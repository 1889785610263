import { Link } from "gatsby";
import React, { useContext } from "react";
import { LanguageContext } from "../../../context/language-context";
import useWindowSize from "../../../lib/use-window-size";
import { slugs2url } from "../../../resources/categorie";
import CardInfoBlok from "./card-info-blok";
import semicerchio from "../../../resources/images/semicerchio.svg";
import { cat2bgImg } from "../../../resources/images";

const ProductCard = ({ story, layout }) => {
  const l = useContext(LanguageContext).locale;
  const cat = story.field_component.replace("pagina-", "");
  const { isMobile } = useWindowSize();
  const image = (story?.content?.imgs ?? [null])[0];
  let className = "is-product-card",
    style = {};
  switch (layout) {
    case "home":
      className = "is-product-card";
      style = isMobile
        ? { flex: `0 0 calc(100vw - 4.25rem)` }
        : { flex: `0 0 285px` };
      break;
    case "category":
    default:
      className = "sm:w-is-product-card mb-14 sm:mb-0 is-product-card";
      break;
  }
  //console.log({ story, cat });
  return (
    <div className={className} style={style}>
      <Link to={`${slugs2url[cat][l]}/${story.slug}`}>
        <div className="overflow-hidden relative">
          <img
            src={semicerchio}
            alt=""
            className="absolute w-full left-0 bottom-0"
          />
          {image ? (
            <div className="flex justify-center">
              <img
                src={image.filename}
                alt={image?.alt ?? ""}
                className="relative z-10 max-h-[250px]"
              />
            </div>
          ) : (
            cat2bgImg[cat]
          )}
        </div>
        <div className="is-b-card pt-4 pb-6 px-6 text-is-range-slider">
          <div className="mb-4">
            <h2 className="is-h3b text-is-blue mb-1">
              {story?.content?.titolo ?? story.name}
            </h2>
            {story?.content?.sottotitolo && (
              <p className="text-sm">{story.content.sottotitolo}</p>
            )}
          </div>
          <CardInfoBlok content={story.content} />
        </div>
      </Link>
    </div>
  );
};

ProductCard.defaultProps = {
  layout: "category",
};

export default ProductCard;
