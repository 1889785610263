import React, { useContext, useMemo, useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import ctaHomeBg from "../../resources/images/cta-home-bg.png";
import tempIc from "../../resources/images/temp.svg";
import muscleIc from "../../resources/images/muscle.svg";
import cogIc from "../../resources/images/cog.svg";
import playButton from "../../resources/images/in-ripr.svg";
import vid from "../../resources/images/vid.mp4";
import pos from "../../resources/images/poster.png";
import useWindowSize from "../../lib/use-window-size";
import CTA from "../cta";
import { Tab } from "@headlessui/react";
import {
  categories,
  slugs2name,
  slugs2url,
  subcat2urlpart,
} from "../../resources/categorie";
import ProductCard from "../storyblok-components/cards/product-card";
import prodListingRArr from "../../resources/images/product-listing-r-arrow.svg";
import prodListingLArr from "../../resources/images/product-listing-l-arrow.svg";
import t from "../../resources/translations";
import { LanguageContext } from "../../context/language-context";
import { cat2bgImg } from "../../resources/images";
import useProductDict from "../use-products-dict";
import { WishlistContext } from "../../context/wishlist-context";
// import homeCtaImg from "../../resources/images/leaderboard.jpg";

const IndexPage = ({ location }) => {
  const l = useContext(LanguageContext).locale;

  const productDict = useProductDict();

  const feats = [
    {
      title: t.featTemp[l],
      text: t.featTempT[l],
      link: t.featTempL[l],
      icon: tempIc,
    },
    {
      title: t.featHard[l],
      text: t.featHardT[l],
      link: t.featHardL[l],
      icon: muscleIc,
    },
    {
      title: t.featLame[l],
      text: t.featLameT[l],
      link: t.featLameL[l],
      icon: cogIc,
    },
  ];

  const revs = [
    { tit: "Video 1", src: vid, poster: pos, duration: "04:30" },
    { tit: "Video 2", src: vid, poster: pos, duration: "04:30" },
    { tit: "Video 3", src: vid, poster: pos, duration: "04:30" },
  ];

  return (
    <Layout initMenuOpen={true} location={location}>
      <CTAHome />
      <div className="mt-6 md:mt-9 flex gap-4 md:gap-12 flex-wrap">
        {feats.map((feat) => (
          <Feat data={feat} key={feat.title} />
        ))}
      </div>
      <CategorieProdotti />
      <NostriProdotti data={productDict} />
      {/* <Reviews reviews={revs} /> */}
      <CTA />
    </Layout>
  );
};

const CTAHome = () => {
  const { isMobile } = useWindowSize();
  const { setContattaci } = useContext(WishlistContext);
  const l = useContext(LanguageContext).locale;
  return (
    <div
      className="is-card flex flex-wrap mt-3.5 md:mt-6 overflow-hidden relative"
      style={
        isMobile
          ? {}
          : {
              width: "calc(75% - 24px)",
              marginLeft: "calc(25% + 24px)",
              minHeight: "412px",
            }
      }
    >
      <div className="absolute w-full h-full top-0 left-0 hidden md:block">
        <StaticImage
          // src="../../resources/images/leaderboard.jpg"
          src="../../resources/images/leaderBoard-ok.jpg"
          alt="lama circolare"
          layout="fullWidth"
          loading="eager"
          placeholder="tracedSVG"
          formats={["webp", "auto"]}
          quality={100}
          className="h-full object-cover"
        />
        {/* <img src={homeCtaImg} alt="" className="w-full h-full object-cover" /> */}
      </div>
      <div className="md:hidden w-full">
        <StaticImage
          // src="../../resources/images/cta-home-mob.png"
          src="../../resources/images/LeaderBoardMob.jpg"
          alt="lama circolare"
          layout="fullWidth"
          loading="eager"
        />
      </div>
      <div
        className="py-3 px-6 text-white md:w-1/2 md:pl-11 md:pr-20 md:py-7 md:flex md:flex-col md:gap-9 items-start relative z-10"
        style={{
          backgroundImage: isMobile ? `url(${ctaHomeBg})` : "none",
          backgroundPosition: "bottom right",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1 className="is-h1m md:is-h1">{t.ctaTitolo[l]}</h1>
        <p className="pt-3 mb-9 md:pt-0 md:mb-0 md:flex-auto">
          {t.ctaTesto[l]}
        </p>
        <button
          onClick={() => {
            setContattaci(true);
          }}
          className="is-button-ter inline-block mb-4 md:mb-0"
        >
          {t.ctaBottone[l]}
        </button>
      </div>
    </div>
  );
};

const Feat = ({ data }) => (
  <Link
    to={data.link}
    className="is-card w-full md:flex-1 py-4 px-4 flex gap-4 md:gap-10 justify-center items-center"
  >
    <div className="w-5/12 flex justify-center">
      <img alt="" src={data.icon} className="w-14" />
    </div>
    <div className="text-white">
      <h2 className="is-h3b text-is-blue">{data.title}</h2>
      <p>{data.text}</p>
    </div>
  </Link>
);

const CategorieProdotti = () => {
  const l = useContext(LanguageContext).locale;
  return (
    <div className="mt-10 md:mt-12">
      <div className="flex">
        <h2 className="is-h2 mr-2.5 text-is-blue">{t.catProd[l]}</h2>
        <div className="h-px bg-white hidden md:block flex-grow self-end mb-3" />
      </div>
      <div className="flex flex-wrap mt-8 md:mt-9 gap-7 md:gap-12">
        {Object.entries(categories)
          .filter(([cat]) => !(cat === "elettroutensili"))
          .map(([cat, subCats]) => (
            <CatBlock key={cat} cat={cat} subCats={subCats} />
          ))}
      </div>
    </div>
  );
};

const CatBlock = ({ cat, subCats }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <div className="flex flex-grow w-full md:w-1/4 is-card overflow-hidden">
      <div className="self-end" style={{ flex: "2 1 0%" }}>
        {cat2bgImg[cat]}
      </div>
      <div
        className=" text-white text-right pr-7 py-7"
        style={{ flex: "1 1 0%" }}
      >
        <h3 className="is-h3b text-is-blue">
          <Link to={slugs2url[cat][l]}>{slugs2name[cat][l]}</Link>
        </h3>
        <ul className="mt-4">
          {subCats.map((s) => (
            <li key={s}>
              <Link to={`${slugs2url[cat][l]}${subcat2urlpart[s][l]}`}>
                {slugs2name[s][l]}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const NostriProdotti = ({ data }) => {
  const l = useContext(LanguageContext).locale;
  const [currentLeft, setCurrentLeft] = useState(0);
  const [x0, setX0] = useState(null);
  const { width } = useWindowSize();
  const maxLeft = useMemo(() => {
    return typeof document !== "undefined"
      ? [...document.querySelectorAll(".cat-el")].reduce(
          (cum, nex) => cum + (nex?.offsetWidth ?? 0),
          0
        ) -
          (document.querySelector(".cat-cont")?.offsetWidth ?? 0) +
          30
      : 300;
  }, [data, width]);

  const startMove = (e) => {
    setX0(e.changedTouches[0].clientX);
  };

  const move = (e) => {
    if (x0 !== null) {
      const dx = e.changedTouches[0].clientX - x0;
      setCurrentLeft((oldLeft) => Math.max(0, Math.min(maxLeft, oldLeft - dx)));
      setX0(e.changedTouches[0].clientX);
    }
  };

  const endMove = () => {
    setX0(null);
  };

  return (
    <Tab.Group as="div" className="mt-10 md:mt-14 px-5 md:px-0">
      <div className="md:flex md:gap-4">
        <h2 className="text-is-blue is-h2">I nostri prodotti</h2>
        <div className="h-px bg-white hidden md:block flex-grow self-end mb-3" />
        <div
          className="max-w-full overflow-hidden cat-cont"
          onTouchStart={startMove}
          onTouchMove={move}
          onTouchEnd={endMove}
        >
          <Tab.List
            as="div"
            className="flex md:gap-3 mt-7 md:mt-0 relative"
            style={{ left: `${-currentLeft}px` }}
          >
            {Object.keys(data[l]).map((cat) => (
              <Tab key={cat}>
                {({ selected }) => (
                  <div
                    className={`cat-el px-3.5 py-1.5 text-white rounded-full whitespace-nowrap ${
                      selected ? "bg-is-blue" : ""
                    }`}
                  >
                    {slugs2name[cat][l]}
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>
        </div>
      </div>
      <Tab.Panels>
        {Object.entries(data[l]).map(([cat, prods]) => (
          <Tab.Panel key={cat}>
            <CatHomeListing products={prods.slice(0, 6)} />
            <div className="text-center">
              <Link
                to={slugs2url[cat][l]}
                className="mt-12 is-button inline-block"
              >
                {t.seeAllProd[l]}
              </Link>
            </div>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

const CatHomeListing = ({ products }) => {
  // const [currentLeft, setCurrentLeft] = useState(0);
  // const [x0, setX0] = useState(null);
  // const { width } = useWindowSize();
  // const maxLeft = useMemo(() => {
  //   return (
  //     [...document.querySelectorAll(".is-product-card")].reduce(
  //       (cum, nex) => cum + (nex?.offsetWidth ?? 0),
  //       0
  //     ) -
  //     (document.querySelector(".product-listing")?.offsetWidth ?? 0) +
  //     30
  //   );
  // }, [products, width]);

  // const startMove = (e) => {
  //   console.log({ x: e.changedTouches[0].clientX });
  //   setX0(e.changedTouches[0].clientX);
  // };

  // const move = (e) => {
  //   console.log({ x: e.changedTouches[0].clientX });
  //   if (x0 !== null) {
  //     const dx = e.changedTouches[0].clientX - x0;
  //     setCurrentLeft((oldLeft) => Math.max(0, Math.min(maxLeft, oldLeft - dx)));
  //     setX0(e.changedTouches[0].clientX);
  //   }
  //   console.log({ currentLeft });
  // };

  // const endMove = () => {
  //   setX0(null);
  // };
  const [activeIdx, setActiveIdx] = useState(0);
  const { isMobile } = useWindowSize();
  const next = () => {
    setActiveIdx((old) =>
      Math.min(old + 1, products.length - (isMobile ? 1 : 4))
    );
  };
  const prev = () => {
    setActiveIdx((old) => Math.max(old - 1, 0));
  };
  return (
    <div className="relative">
      <button
        className="absolute w-9 h-9 z-10 rounded-full bg-is-dark-gray shadow-is flex justify-center items-center -left-5 md:left-2 2xl:-left-14 top-1/2 md:border 2xl:border-none border-white"
        onClick={prev}
      >
        <img src={prodListingLArr} alt="prodotto precedente" />
      </button>
      <button
        className="absolute w-9 h-9 z-10 rounded-full bg-is-dark-gray shadow-is flex justify-center items-center -right-5 md:right-2 2xl:-right-14 top-1/2 md:border 2xl:border-none border-white"
        onClick={next}
      >
        <img src={prodListingRArr} alt="prodotto successivo" />
      </button>
      <div
        className="relative overflow-hidden max-w-full mt-10 product-listing round p-8 bg-is-bg-prodotti"
        style={{
          borderRadius: "20px",
        }}
        // onTouchStart={startMove}
        // onTouchMove={move}
        // onTouchEnd={endMove}
      >
        <div
          className="flex gap-12 relative transition-all"
          style={
            isMobile
              ? {
                  left: `calc( -${activeIdx * 100}vw + ${activeIdx * 1.25}rem)`,
                }
              : { left: `calc( -${285 * activeIdx}px - ${activeIdx * 3}rem )` }
          }
        >
          {products.map((p) => (
            <ProductCard key={p.name} story={p} layout="home" />
          ))}
        </div>
        <div
          className="absolute w-full h-full bg-transparent top-0 left-0 pointer-events-none"
          style={{
            borderRadius: "20px",
            boxShadow: "inset 0px 0px 20px rgb(0 0 0)",
          }}
        />
      </div>
    </div>
  );
};

const Reviews = ({ reviews }) => {
  return (
    <Tab.Group
      as="div"
      className="flex items-start flex-wrap gap-3.5 md:gap-7 mt-11 md:pt-14 md:mt-16 md:border-white md:border-t"
      vertical
      manual
    >
      <Tab.Panels as="div" className="w-full md:w-auto md:flex-grow-2">
        {reviews.map((rev) => (
          <Tab.Panel key={rev.tit}>
            <video
              src={rev.src}
              // poster={rev.poster}
              controls
              className="w-full"
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
      <Tab.List
        as="div"
        className="w-full is-card py-4 px-2 text-white md:w-1/4 md:flex-grow-1"
      >
        <h2 className="is-h3b pl-10">Reviews</h2>
        <div className="bg-is-rev rounded-is py-11 flex flex-wrap gap-4 mt-4">
          {reviews.map((rev) => (
            <Tab key={rev.tit}>
              {({ selected }) => (
                <div className="flex gap-4 px-8 w-full text-left items-center relative">
                  {selected && (
                    <div
                      className="absolute flex items-center left-0 top-0 h-full border-is-blue border-solid"
                      style={{ borderLeftWidth: "6px" }}
                    >
                      <img src={playButton} alt="" />
                    </div>
                  )}
                  <img src={rev.poster} alt="" className="w-5/12" />
                  <div className="flex-1 w-1/3">
                    <h4 className="is-h4">{rev.tit}</h4>
                    <p className="text-is-rev-duration">{rev.duration}</p>
                    <p className="text-is-red mt-1">
                      {selected && "In riproduzione"}&nbsp;
                    </p>
                  </div>
                </div>
              )}
            </Tab>
          ))}
        </div>
        <Link
          to="/"
          title="See all reviews"
          className="inline-block is-button ml-5 mt-6"
        >
          Vedi tutte le recensioni
        </Link>
      </Tab.List>
    </Tab.Group>
  );
};

export default IndexPage;
