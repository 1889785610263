import React, { useContext, useMemo } from "react";
import { LanguageContext } from "../../../context/language-context";
import { materials } from "../../../resources/categorie";
import { attr2icon } from "../../../resources/component-mapping";
import { FromTo, getMaterials } from "./utils";

const CardInfoBlok = ({ content }) => {
  const l = useContext(LanguageContext).locale;
  //console.log("CONTENT IS");
  //console.log(content);
  let productData = {};
  (content?.varianti ?? []).forEach((v) => {
    Object.entries(v).forEach(([n, v]) => {
      if (n in attr2icon) {
        if (n in productData) {
          productData[n].push(v);
        } else {
          productData[n] = [v];
        }
      }
    });
  });

  const materiali = useMemo(() => getMaterials(content), [content]);

  return (
    <div className="flex flex-wrap gap-5">
      {Object.entries(productData).map(([n, arr]) => (
        <div key={n} className="flex items-center gap-2.5 flex-wrap">
          <img src={attr2icon[n]} alt={n} className="w-5" />
          <FromTo values={arr} />
        </div>
      ))}
      <div className="flex gap-2 items-start flex-wrap">
        {materiali.map((m) => (
          <div className="p-1.5 border border-white text-white uppercase">
            {materials[m][l]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardInfoBlok;
